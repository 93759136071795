<template>
  <div>
    <!--begin::customer-->
    <b-card no-body class="mb-5">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
          {{ $t('advanced_filter') }}
        </b-button>
      </b-card-header>
      <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row mb-5">

              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('sales_by_client_report.customer') }}</label>
                <!-- <select name="" id="customer_id" v-model="filters.customer_id" class="custom-select">
                    <option v-for="row in customers" :value="row.id" :key="'customer'+row.id">
                        {{ row.fullname }}
                    </option>
                </select> -->
                <multiselect v-model="customer"
                             :placeholder="$t('sales_by_client_report.customer')"
                             label="fullname"
                             track-by="id"
                             :options="customers"
                             :multiple="false"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             @search-change="getCustomers($event)">
                </multiselect>
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="from_date">{{ $t('sales_by_client_report.from_date') }}</label>
                <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="to_date">{{ $t('sales_by_client_report.to_date') }}</label>
                <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
              </div>
              <div class="form-group col-md-6 mt-10">
                <b-form-group :label="''" v-slot="{ ariaDescribedby }">
                  <b-form-radio v-model="filters.date_by" :aria-describedby="ariaDescribedby" name="some-radios" value="1">{{ $t('invoice') }}</b-form-radio>
                  <b-form-radio v-model="filters.date_by" v-if="filters.filter_by == 'customer'" :aria-describedby="ariaDescribedby" name="some-radios" value="2">{{ $t('customer') }}</b-form-radio>
                  <b-form-radio v-model="filters.date_by" v-if="filters.filter_by == 'employee'" :aria-describedby="ariaDescribedby" name="some-radios" value="3">{{ $t('employee') }}</b-form-radio>
                </b-form-group>
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('sales_by_client_report.added_by') }}</label>
                <!-- <select name="" id="added_by" v-model="filters.user_id" class="custom-select">
                    <option v-for="row in users" :value="row.id" :key="'by'+row.id">
                        {{ row.name }}
                    </option>
                </select> -->
                <multiselect v-model="user"
                             :placeholder="$t('sales_by_client_report.added_by')"
                             label="name"
                             track-by="id"
                             :options="users"
                             :multiple="false"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             @search-change="getUsers($event)">
                </multiselect>
              </div>
              <!--                            <div class="form-group col-md-6 mt-2 mb-2">-->
              <!--                                <label>{{$t('sales_by_client_report.sales_man')}}</label>-->
              <!--                                <multiselect v-model="sales_man"-->
              <!--                                             :placeholder="$t('sales_by_client_report.sales_man')"-->
              <!--                                             label="name"-->
              <!--                                             track-by="id"-->
              <!--                                             :options="employees"-->
              <!--                                             :multiple="false"-->
              <!--                                             :taggable="false"-->
              <!--                                             :show-labels="false"-->
              <!--                                             :show-no-options="false"-->
              <!--                                             :show-no-results="false"-->
              <!--                                             @search-change="getEmployees($event)">-->
              <!--                                </multiselect>-->
              <!--                            </div>-->

              <!--                            <div class="form-group col-md-6 mt-2 mb-2">-->
              <!--                                <label for="f_status">{{$t('status')}}</label>-->
              <!--                                <select name="" id="f_status" v-model="is_paid" type="text" class="custom-select">-->
              <!--                                    <option value="">{{$t('all')}}</option>-->
              <!--                                    <option v-for="(row, index) in status_list" :value="row.id" :key="'status'+index">{{ row.title }}</option>-->
              <!--                                </select>-->
              <!--                            </div>-->


              <div class="form-group d-inline-flex col-md-6 mt-10">
                <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                  {{ $t('display_report') }}
                </button>
                <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
                  <i class="fas fa-trash-restore"></i> {{ $t('reset_search') }}
                </button>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>
    <!-- End Filter -->
    <div class="card card-custom">
      <div class="card-body">

        <!-- Filter -->
        <div>

          <div class="m-form m-form--fit m--margin-bottom-20">

            <b-tabs content-class="mt-3">
              <b-tab :title="$t('summery')" :active="tab == 'summery' || tab == null">
                <div class="row mb-10">
                  <div class="col-md-12 mt-10" id="summaryTableTitle">
                    <h4 class="text-center">{{ $t('sales_by_client_report.sales_by_client_report') }}</h4>
                    <h5 class="text-center" v-if="filters.from_date && filters.to_date">{{ $t('from') }} {{ filters.from_date }} {{ $t('to') }} {{ filters.to_date }}</h5>
                  </div>
                  <div class="col-md-6 mt-10" v-if="line_invoice_details">
                    <LineChart :data-line-invoice="line_invoice_details" :currency-name="currency_name"/>
                  </div>
                  <div class="col-md-6 mt-10" v-if="paid_unpaid_details">
                    <DoughnutChart :data-paid-unpaid="paid_unpaid_details" :currency-name="currency_name"/>
                  </div>
                </div>
                <div class="row mb-10">
                  <div class="col-12" v-if="data_report_details">
                    <div class="table-responsive">
                      <div class="row justify-content-end p-4">
                        <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                          <download-excel
                              class="dropdown-item"
                              :fetch="salesInvoiceExport"
                              :fields="json_fields"
                              :name="$t('MENU.sales_reports')+'.xls'">
                            <i class="la la-file-excel"></i>{{ $t('excel') }}
                          </download-excel>
                          <button class="dropdown-item" @click="printData('summaryTable', 'print')">
                            <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                          </button>
                          <button class="dropdown-item" @click="printData('summaryTable', 'pdf')">
                            <span><i class="fa fa-file-pdf"></i> <span>{{ $t('export_pdf') }}</span></span>
                          </button>
                        </b-dropdown>
                      </div>
                      <table class="table table-bordered" id="summaryTable">
                        <thead>
                        <tr>
                          <th v-if="isPeriod">{{ $t('sales_by_client_report.' + periodLabel) }}</th>
                          <th v-else>{{ $t('sales_by_client_report.customer') }}</th>
                          <template v-if="!isPeriod">
                            <th>{{ $t('sales_by_client_report.paid') }} ({{ currency_name }})</th>
                            <th>{{ $t('sales_by_client_report.unpaid') }} ({{ currency_name }})</th>
                          </template>
                          <th>{{ $t('sales_by_client_report.total') }} ({{ currency_name }})</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(row2, index2) in data_report_summary" :key="'a'+getRandom()+index2">
                          <td>{{ row2.label }}</td>
                          <template v-if="!isPeriod">
                            <td>{{ row2.paid }}</td>
                            <td>{{ row2.unpaid }}</td>
                          </template>
                          <td>{{ row2.total }}</td>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr>
                          <td><b>{{ $t('sales_by_client_report.total') }}</b></td>
                          <template v-if="!isPeriod">
                            <th>{{ summary_total_sum.sum_paid ? summary_total_sum.sum_paid : 0 }}</th>
                            <th>{{ summary_total_sum.sum_unpaid ? summary_total_sum.sum_unpaid : 0 }}</th>
                          </template>
                          <th>{{ summary_total_sum.sum_total ? summary_total_sum.sum_total : 0 }}</th>
                        </tr>
                        </tfoot>
                      </table>
                    </div>

                  </div>
                </div>
              </b-tab>

              <b-tab :title="$t('details')" :active="tab == 'details'">
                <div class="mt-3">
                  <div class="row mb-10">
                    <div class="col-md-12 mt-10" id="summaryTableDetailsTitle">
                      <h4 class="text-center">{{ $t('sales_by_client_report.sales_by_client_report') }}</h4>
                      <h5 class="text-center" v-if="filters.from_date && filters.to_date">{{ $t('from') }} {{ filters.from_date }} {{ $t('to') }} {{ filters.to_date }}</h5>
                    </div>
                    <div class="col-md-6 mt-10" v-if="line_invoice_details">
                      <LineChart :data-line-invoice="line_invoice_details" :currency-name="currency_name"/>
                    </div>
                    <div class="col-md-6 mt-10" v-if="paid_unpaid_details">
                      <DoughnutChart :data-paid-unpaid="paid_unpaid_details" :currency-name="currency_name"/>
                    </div>
                  </div>
                  <div class="row mb-10">
                    <div class="col-12" v-if="data_report_details">
                      <div class="table-responsive">
                        <div class="row justify-content-end p-4">
                          <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                            <download-excel
                                class="dropdown-item"
                                :fetch="salesInvoiceDetailsExport"
                                :fields="json_fields_details"
                                :name="$t('MENU.sales_reports')+'.xls'">
                              <i class="la la-file-excel"></i>{{ $t('excel') }}
                            </download-excel>
                            <button class="dropdown-item" @click="printData('summaryTableDetails', 'print')">
                              <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                            </button>
                            <button class="dropdown-item" @click="printData('summaryTableDetails', 'pdf')">
                              <span><i class="fa fa-file-pdf"></i> <span>{{ $t('export_pdf') }}</span></span>
                            </button>
                          </b-dropdown>
                        </div>
                        <table class="table table-bordered" id="summaryTableDetails">
                          <thead>
                          <tr>
                            <th>{{ $t('sales_by_client_report.number') }}</th>
                            <th>{{ $t('sales_invoices.invoice_code') }}</th>
                            <th>{{ $t('sales_by_client_report.date') }}</th>
                            <th v-if="filters.filter_by != 'customer'">{{ $t('sales_by_client_report.customer') }}</th>
                            <th v-if="filters.filter_by != 'customer'">{{ $t('payment_sales_invoices.customer_code') }}</th>
                            <th>{{ $t('discounts') }}</th>
                            <th>{{ $t('sales_by_client_report.tax') }} ({{ currency_name }})</th>
                            <th>{{ $t('sales_by_client_report.refund') }} ({{ currency_name }})</th>
                            <th>{{ $t('sales_by_client_report.paid') }} ({{ currency_name }})</th>
                            <th>{{ $t('sales_by_client_report.unpaid') }} ({{ currency_name }})</th>
                            <th>{{ $t('sales_by_client_report.total') }} ({{ currency_name }})</th>
                          </tr>
                          </thead>
                          <tbody>
                          <template v-for="(row, index) in data_report_details">
                            <tr :key="'a'+index">
                              <td :colspan="filters.filter_by != 'customer' ? 11 : 9">{{ row.label }}</td>
                            </tr>
                            <tr v-for="(row2, index2) in row.details" :key="'a'+getRandom()+index2">
                              <td>{{ row2.id }}</td>
                              <td>{{ row2.invoice_code }}</td>
                              <td>{{ row2.date }}</td>
                              <td v-if="filters.filter_by != 'customer'">{{ row2.customer_name }}</td>
                              <td v-if="filters.filter_by != 'customer'">{{ row2.customer_code }}</td>
                              <td>{{ row2.discounts }}</td>
                              <td>{{ row2.taxes ? row2.taxes : 0 }}</td>
                              <td>{{ row2.refund ? row2.refund : 0 }}</td>
                              <td>{{ row2.paid }}</td>
                              <td>{{ row2.unpaid }}</td>
                              <td>{{ row2.total }}</td>
                            </tr>
                            <tr :key="'b'+index">
                              <td :colspan="filters.filter_by != 'customer' ? 5 : 3">{{ $t('sales_by_client_report.total') }}</td>
                              <td>{{ row.total.sum_discounts }}</td>
                              <td>{{ row.total.sum_taxes }}</td>
                              <td>{{ row.total.sum_refund }}</td>
                              <td>{{ row.total.sum_paid }}</td>
                              <td>{{ row.total.sum_unpaid }}</td>
                              <td>{{ row.total.sum_total }}</td>
                            </tr>
                          </template>

                          </tbody>
                          <tfoot>
                          <tr>
                            <td :colspan="filters.filter_by != 'customer' ? 5 : 3"><b>{{ $t('sales_by_client_report.total') }}</b></td>
                            <th>{{ details_total_sum.sum_discounts_total ? details_total_sum.sum_discounts_total : 0 }}</th>
                            <th>{{ details_total_sum.sum_taxes_total ? details_total_sum.sum_taxes_total : 0 }}</th>
                            <th>{{ details_total_sum.sum_refund_total ? details_total_sum.sum_refund_total : 0 }}</th>
                            <th>{{ details_total_sum.sum_paid_total ? details_total_sum.sum_paid_total : 0 }}</th>
                            <th>{{ details_total_sum.sum_unpaid_total ? details_total_sum.sum_unpaid_total : 0 }}</th>
                            <th>{{ details_total_sum.sum_total_total ? details_total_sum.sum_total_total : 0 }}</th>
                          </tr>
                          </tfoot>
                        </table>
                      </div>

                    </div>
                  </div>
                </div>
              </b-tab>
              <template v-if="isSystemUser" #tabs-end>
                <li class="nav-item  d-flex my-auto">
                  <select name="" id="" v-model="filters.filter_by" class="custom-select border-0 pt-0 pb-0 h-auto shadow-none">
                    <option :value="null">{{ $t('select_filter') }}</option>
                    <option value="daily">{{ $t('daily') }}</option>
                    <option value="weekly">{{ $t('weekly') }}</option>
                    <option value="monthly">{{ $t('monthly') }}</option>
                    <option value="yearly">{{ $t('yearly') }}</option>
                    <option value="customer">{{ $t('customer') }}</option>
                    <option value="employee">{{ $t('employee') }}</option>
                    <!--                                        <option value="sales_man">{{$t('sales_man')}}</option>-->
                  </select>

                </li>
              </template>
              <!-- <b-tab> -->
              <!-- </b-tab> -->
            </b-tabs>
            <div class="col-12 text-center" v-if="page">
              <button class="btn btn-warning" @click="loadMore">
                <p class="mb-0">
                  <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
                  {{ $t('load_more') }}
                </p>
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="selector-export" id="selectorExport"></div>
    <!--end::customer-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import DoughnutChart from './components/Doughnut.vue';
import LineChart from './components/Line.vue';
import exportPDFInnerAction from "@/core/plugins/export-jspdf-inner-action";
import {mapGetters} from "vuex";

export default {
  name: "index-sales-by-client-report",
  components: {DoughnutChart, LineChart},
  data() {
    return {
      mainRoute: '/reports/sales',
      mainRouteDependency: 'base/dependency',

      currency_name: null,

      line_invoice_details: [],
      paid_unpaid_details: [],

      data_report_details: [],
      details_total_sum: [],

      data_report_summary: [],
      summary_total_sum: [],


      filters: {
        customer_id: null,
        from_date: null,
        to_date: null,
        user_id: null,
        overdue: null,
        f_status: null,
        sales_man_id: null,
        filter_by: null,
        date_by: 1,
      },
      is_paid: null,
      status_list: [
        {id: 1, title: this.$t('paid')},
        {id: 2, title: this.$t('partial_paid')},
        {id: 3, title: this.$t('unpaid')},
        {id: 4, title: this.$t('refund')},
        {id: 5, title: this.$t('overdue')},
      ],

      sales_man: null,
      data: [],
      customers: [],
      users: [],
      employees: [],
      isDisabled: true,
      isPeriod: false,
      periodLabel: '',
      customer: null,
      user: null,
      tab: this.$route.query.tab ? this.$route.query.tab : null,
      json_meta: [
        [{
          key: "charset",
          value: "utf-8",
        },],
      ],
      page: 0,
    }
  },
  watch: {
    "filters.filter_by": function (val) {
      if (val)
        this.doFilter();
      switch (val) {
        case 'daily':
          this.isPeriod = true;
          this.periodLabel = 'day';
          break;
        case 'weekly':
          this.isPeriod = true;
          this.periodLabel = 'week';
          break;
        case 'monthly':
          this.isPeriod = true;
          this.periodLabel = 'month';
          break;
        case 'yearly':
          this.isPeriod = true;
          this.periodLabel = 'year';
          break;
        default:
          this.isPeriod = false;
          this.periodLabel = '';
          break;
      }
    },
    sales_man: function (val) {
      if (val) {
        this.filters.sales_man_id = val.id;
      } else {
        this.filters.sales_man_id = null;
      }
    },
    is_paid: function (val) {
      if (val == 5) {
        this.filters.f_status = null;
        this.filters.overdue = 1;
      } else {
        this.filters.f_status = val;
        this.filters.overdue = null;
      }
    },
    customer: function (val) {
      if (val) {
        this.filters.customer_id = val.id;
      } else {
        this.filters.customer_id = null;
      }
    },
    user: function (val) {
      if (val) {
        this.filters.user_id = val.id;
      } else {
        this.filters.user_id = null;
      }
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    isSystemUser: function () {
      return this.currentUserPersonalInfo.is_system_user
    },
    json_fields: function () {
      let fields = {};

      if (this.isPeriod) {
        fields[this.$t('sales_by_client_report.' + this.periodLabel)] = 'label';
      } else {
        fields[this.$t('sales_by_client_report.customer')] = 'label';
        fields[this.$t('sales_by_client_report.paid')] = 'paid';
        fields[this.$t('sales_by_client_report.unpaid')] = 'unpaid';
      }

      fields[this.$t('sales_by_client_report.total') + ` (${this.currency_name})`] = 'total';

      return fields;
    },

    json_fields_details: function () {
      let fields = {};

      fields[this.$t('sales_by_client_report.number')] = 'id';
      fields[this.$t('sales_invoices.invoice_code')] = 'invoice_code';
      if (this.filters.filter_by != 'customer') {
        fields[this.$t('sales_by_client_report.customer')] = 'customer_name';
        fields[this.$t('payment_sales_invoices.customer_code')] = 'customer_code';
      }

      fields[this.$t('sales_by_client_report.date')] = 'date';
      fields[this.$t('discounts')] = 'discounts';
      fields[this.$t('sales_by_client_report.tax')] = 'taxes';
      fields[this.$t('sales_by_client_report.refund')] = 'refund';
      fields[this.$t('sales_by_client_report.paid')] = 'paid';
      fields[this.$t('sales_by_client_report.unpaid')] = 'unpaid';
      fields[this.$t('sales_by_client_report.total') + ` (${this.currency_name})`] = 'total';

      return fields;
    },


  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.sales_reports"), route: '/reports/sales-reports-links'}, {title: this.$t("MENU.sales_reports")}]);

    this.filters.filter_by = this.$route.query.filter ? this.$route.query.filter : null;

    // this.getUsers();
    // this.getEmployees();
    // this.getStatusList();
    this.getAuthUser();
  },
  methods: {

    salesInvoiceExport() {
      this.setReportLog('excel', 'sales report');
      let _footer = {
        label: this.$t('sales_by_client_report.total') + ` (${this.currency_name})`,
        discounts: this.summary_total_sum.sum_discounts,
        taxes: this.summary_total_sum.sum_taxes,
        refund: this.summary_total_sum.sum_refund,
        paid: this.summary_total_sum.sum_paid,
        unpaid: this.summary_total_sum.sum_unpaid,
        total: this.summary_total_sum.sum_total,
      };
      let _data = [];
      this.data_report_summary.forEach((row) => {
        _data.push({
          label: row.label,
          paid: row.paid,
          unpaid: row.unpaid,
          total: row.total,
        });
      });
      _data.push(_footer);

      return _data;
    },
    salesInvoiceDetailsExport() {
      this.setReportLog('excel', 'sales report');
      let _footer = {
        id: this.$t('sales_by_client_report.total') + ` (${this.currency_name})`,
        invoice_code: '',
        customer_name: '',
        customer_code: '',
        date: '',
        discounts: this.details_total_sum.sum_discounts_total,
        taxes: this.details_total_sum.sum_taxes_total,
        refund: this.details_total_sum.sum_refund_total,
        paid: this.details_total_sum.sum_paid_total,
        unpaid: this.details_total_sum.sum_unpaid_total,
        total: this.details_total_sum.sum_total_total,
      };
      let _data = [];
      this.data_report_details.forEach((row) => {
        _data.push({
          id: row.label,
          invoice_code: '',
          customer_name: '',
          customer_code: '',
          date: '',
          discounts: '',
          taxes: '',
          refund: '',
          paid: '',
          unpaid: '',
          total: '',
        });
        row.details.forEach((sub_row) => {
          _data.push({
            id: sub_row.id,
            invoice_code: sub_row.invoice_code,
            customer_name: sub_row.customer_name,
            customer_code: sub_row.customer_code,
            date: sub_row.date,
            discounts: sub_row.discounts,
            taxes: sub_row.taxes,
            refund: sub_row.refund,
            paid: sub_row.paid,
            unpaid: sub_row.unpaid,
            total: sub_row.total,
          });
        });
        _data.push({
          id: this.$t('revenue_invoices.total') + ` (${this.currency_name})`,
          invoice_code: '',
          customer_name: '',
          customer_code: '',
          date: '',
          discounts: row.total.sum_discounts,
          taxes: row.total.sum_taxes,
          refund: row.total.sum_refund,
          paid: row.total.sum_paid,
          unpaid: row.total.sum_unpaid,
          total: row.total.sum_total,
        });
      });
      _data.push(_footer);

      return _data;
    },

    getRandom() {
      return Math.floor(Math.random() * 10000);
    },

    // fnExcelReport() {
    //     let table = document.getElementById('revenue_invoice');
    //     TableToExcel.convert(table, { // html code may contain multiple tables so here we are refering to 1st table tag
    //         name: this.$t('sales_by_client_report')+'.xlsx', // fileName you could use any name
    //         sheet: {
    //             name: 'Sheet 1' // sheetName
    //         }
    //     });
    // },
    doFilter() {
      this.page = 1;
      this.getLineChartInvoiceDetails();
      this.getPaidUnpaidInvoiceDetails();
      this.getReportInvoiceDetails();
      this.getReportInvoiceSummary();

    },
    resetFilter() {
      this.filters.customer_id = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.user_id = null;
      this.is_paid = null;
      this.filters.f_status = null;
      this.filters.overdue = null;
      this.filters.sales_man_id = null;
      this.filters.date_by = 1;
      this.sales_man = null;
      this.customer = null;
      this.user = null;


    },

    // getStatusList() {
    //     ApiService.get(this.mainRouteDependency + "/status").then((response) => {
    //         this.status_list = response.data.data;
    //     });
    // },

    async getCustomers(filter) {
      if (filter && filter.length >= 3)
        await ApiService.get(this.mainRouteDependency + "/customers", {params: {filter: filter}}).then((response) => {
          this.customers = response.data.data;
        });
    },
    getUsers(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/users", {params: {filter: filter}}).then((response) => {
          this.users = response.data.data;
        });
    },
    getAuthUser() {
      ApiService.get(`/get_auth`).then((response) => {
        this.currency_name = response.data.data.currency_name;
      });
    },
    getEmployees(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/employees", {params: {filter: filter}}).then((response) => {
          this.employees = response.data.data;
        });
    },

    loadMore() {
      this.page = this.page ? (this.page + 1) : 1;
      this.getLineChartInvoiceDetails();
      this.getPaidUnpaidInvoiceDetails();
      this.getReportInvoiceDetails();
      this.getReportInvoiceSummary();
    },
    /*****
     * details
     */
    getLineChartInvoiceDetails() {
      ApiService.get(`${this.mainRoute}/revenue_chart`, {params: {...this.filters, page: (this.page ? this.page : 1), type: 'bar'}}).then((response) => {
        this.line_invoice_details = response.data;
      });
    },
    getPaidUnpaidInvoiceDetails() {
      ApiService.get(`${this.mainRoute}/revenue_chart`, {params: {...this.filters, page: (this.page ? this.page : 1), type: 'donut'}}).then((response) => {
        this.paid_unpaid_details = response.data.data;
      });
    },
    getReportInvoiceDetails() {
      ApiService.get(`${this.mainRoute}/revenue_details`, {params: {...this.filters, page: (this.page ? this.page : 1)}}).then((response) => {
        this.data_report_details = response.data.data.data;
        this.details_total_sum = response.data.data.total;
      });
    },
    /**
     * get for summary tab
     */
    getReportInvoiceSummary() {
      ApiService.get(`${this.mainRoute}/revenue_details`, {params: {...this.filters, page: (this.page ? this.page : 1), data_type: 'list'}}).then((response) => {
        this.data_report_summary = response.data.data;
        this.summary_total_sum = response.data.total;
      });
    },
    printData(tableId, type) {
      this.setReportLog('pdf', 'sales report');

      // let divToPrint = document.getElementById(tableId);
      // let divTitleToPrint = document.getElementById(tableId + 'Title');
      // let newWin = window.open("");
      // newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divTitleToPrint.outerHTML + divToPrint.outerHTML);
      // setTimeout(() => {
      //     newWin.print();
      //     newWin.close();
      // }, 100)
      exportPDFInnerAction(tableId, type, this.$t('MENU.sales_reports'), this.$t('MENU.sales_reports'));

    },
    /**
     * register log
     */
    setReportLog(key, description) {
      ApiService.post("reports/system_activity_log/log_type", {
        key: key,
        description: description,
      });
    }
  },
};
</script>
<style>
.html2pdf__overlay {
  opacity: 0 !important;
  z-index: -1000;

}

.selector-export {
  opacity: 0 !important;
  z-index: -1000 !important;
  width: 0.0001px !important;
  height: 0.0001px !important;
  overflow: auto;
}
</style>

